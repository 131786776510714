import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT
} from '../actions/types'

const initialState = {
  user: null,
  client: null,
  error: null,
  loading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    //  Start Action
    case LOGIN:
      return {
        ...state,
        loading: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        client: action.payload.client,
        error: null
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        client: null,
        error: action.payload.error
      }
    case LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
        client: null,
        error: null
      }
    default:
      return state
  }
}
