import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../../actions/authActions'
import { Link } from 'react-router-dom'
import LogoIcon from '../../ui/icons/Logo'
import CalendarIcon from '../../ui/icons/Calendar'
import NewTransactionIcon from '../../ui/icons/NewTransaction'
import PowerIcon from '../../ui/icons/Power'

const MenuContainer = styled.aside`
  width: 15%;
  background-color: #1A2536;
  height: 100vh;
  color: #fff;
`
const MenuHeader = styled.div`
  background-color: #FBFCF8;
  color: #1A2536;
  display: flex;
  padding: 2rem 1rem;
  text-align: center;
  svg{
    width: 20%;
  }
  h2{
    width: 80%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8rem;
    margin: 0;
  }
`
const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const MenuItem = styled.div`
  display: flex;  
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding: 2rem;
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  cursor: pointer;
  &:hover,
  &:focus,
  &.active{
    background-color: #0093C6;
  }
  span{
    font-size: 1.6rem;    
  }
  svg{
    width: 2.5rem;
    margin-right: 1rem;
    fill: #fff;
  }
`

export default function Menu (props) {
  const clientName = useSelector(state => state.auth.client.name)
  const dispatch = useDispatch()

  return (
    <MenuContainer>
      <MenuHeader>
        <LogoIcon />
        <h2>{clientName}</h2>
      </MenuHeader>
      <MenuItemsContainer>
        <Link to='/transacciones/nueva'>
          <MenuItem>
            <NewTransactionIcon />
            <span>Registrar</span>
          </MenuItem>
        </Link>
        <Link to='/transacciones'>
          <MenuItem>
            <CalendarIcon />
            <span>Transacciones</span>
          </MenuItem>
        </Link>
        <MenuItem onClick={() => dispatch(logOut())}>
          <PowerIcon />
          <span>Cerrar Sesión</span>
        </MenuItem>
      </MenuItemsContainer>
    </MenuContainer>
  )
}
