import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeDate } from '../../../actions/transactionActions'
import { formatCurrency } from '../../../utils'
import styled from '@emotion/styled'

const BankDetail = styled.div`
  margin: 2rem 0;
  border: 1px solid #0093C6;
  border-radius: .5rem;
  padding: 3rem;
  h3,
  h4,
  p{
    margin: 0;
    text-align: center;
  }
  h3{
    color: #0093C6;
    font-size: 3rem;
    margin-bottom: 2.5rem;
  }
  h4{
    color: #565C5E;
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: .2rem;
  }
  p{
    color: #565C5E;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }
`
const Additional = styled.div`
  margin: 2rem 0;  
  padding: 3rem;
  h3,
  h4,
  p{
    margin: 0;
    text-align: center;
  }
  h3{
    color: #0093C6;
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  p{
    color: #565C5E;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }
`
export default function CloseDateComponent () {
  const dispatch = useDispatch()
  const { banks, list: transactions, close } = useSelector(state => state.transactions)

  useEffect(() => {
    dispatch(closeDate(banks, transactions))
  }, [dispatch, banks, transactions])

  if (close.loading || !close.banks) {
    return <p>Cargando...</p>
  }

  return (
    <>
      <h2>Cierre</h2>
      <Additional>
        <h3>Adicionales</h3>
        <p> {formatCurrency(close.additional)} </p>
      </Additional>
      {
        Object.keys(close.banks).map(key => (
          <BankDetail key={`${close.banks[key].name}-123`}>
            <h3>{close.banks[key].name}</h3>
            <h4><strong>Recaudos</strong></h4>
            <p>Valor: {formatCurrency(close.banks[key].collection.amount)} - Cantidad: {close.banks[key].collection.quantity} </p>
            <h4><strong>Retiros</strong></h4>
            <p>Valor: {formatCurrency(close.banks[key].withdrawal.amount)} - Cantidad: {close.banks[key].withdrawal.quantity} </p>
            <h4><strong>Depositos</strong></h4>
            <p>Valor: {formatCurrency(close.banks[key].deposit.amount)} - Cantidad: {close.banks[key].deposit.quantity} </p>
          </BankDetail>
        ))
      }
    </>
  )
}
