import React from 'react'

function EditIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <path d='M384.721 0L61.095 323.627 0 512l188.374-61.094L512 127.279zm84.853 127.279l-42.427 42.427-84.853-84.853 42.426-42.427zM80.963 359.61l71.427 71.428-32.036 10.39-49.782-49.782zm14.501-27.925l225.617-225.618 31.82 31.82-225.618 225.617zm53.032 53.032l225.618-225.619 31.82 31.82-225.618 225.619zm-88.313 38.965l28.136 28.136-41.642 13.505z' />
    </svg>
  )
}

export default EditIcon
