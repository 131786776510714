import React from 'react'

function SendIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <path d='M501.44 10.56c-8.86-8.859-21.435-12.449-33.636-9.603l-.168.04L65.557 99.4A85.803 85.803 0 000 182.935V236c0 19.333 15.319 35.156 34.457 35.967L185.7 297.711a10 10 0 008.749-2.787l178.237-178.238c6.249-6.248 16.379-6.248 22.628 0 6.248 6.249 6.248 16.379 0 22.628L217.077 317.552a10 10 0 00-2.787 8.749l25.743 151.243C240.844 496.681 256.667 512 276 512h53.065a85.803 85.803 0 0083.535-65.556l98.402-402.08.04-.168c2.848-12.202-.742-24.776-9.602-33.636z' />
    </svg>
  )
}

export default SendIcon
