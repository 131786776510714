import React from 'react'
import { Global, css } from '@emotion/core'
import { Helmet } from 'react-helmet'

export default function Layout ({ title, children }) {
  return (
    <>
      <Helmet>
        <html lang='es' />
        <meta charset='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>{title}</title>
        <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css' />
        <link href='https://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' />
      </Helmet>

      <Global
        styles={css`
          html {
            font-size: 62.5%;
            box-sizing: border-box; 

          }

          *, *:before, *:after{
            box-sizing: inherit
          }

          *::-webkit-scrollbar-track{
            border-radius: .2rem;
            background-color: #F5F5F5;
          }
          *::-webkit-scrollbar{
            width: .5rem;
            background-color: #F5F5F5;
          }
          *::-webkit-scrollbar-thumb{
            border-radius: .2rem;
            background-color: #ABB3B7;
          }

          body {
            font-size: 1.6rem;
            line-height: 1.5;
            font-family: 'Lato', sans-serif;
            color: #abb3b7;
          }

          h1, h3, h3{
            margin: 0 0 2rem 0;
            line-height: 1.5;
          }
          h1, h2 {
            font-family: 'Lato', serif;
            font-weight: 700;
          }
          h3 {
            font-family: 'Lato', sans-serif;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }
          a {
            text-decoration: none;
          }
          img {
            max-width: 100%;
          }

        `}
      />

      <main>
        {children}
      </main>
    </>
  )
}
