import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates'
import Fuse from 'fuse.js'
import {
  SORT_TRANSACTIONS,
  SORT_TRANSACTIONS_SUCCESS,
  SORT_TRANSACTIONS_ERROR
} from '../../../actions/types'
import { fetchTransactions, searchByBarcode } from '../../../actions/transactionActions'
import Field from '../../ui/Forms/Field'
import DollarIcon from '../../ui/icons/Dollar'
import BarcodeIcon from '../../ui/icons/Barcode'
import CalendarIcon from '../../ui/icons/Calendar'
import CalendarDayIcon from '../../ui/icons/CalendarDay'
import RightModal from '../RightModal'
import CloseDate from '../CloseDay'
import { sleep } from '../../../utils'

const DatesContainer = styled.div`
  width: 20%;
  p{
    margin: .5rem 0;
    strong {
      color: #565C5E;
    }
  }
`
const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  & .DayPicker,
  & .SingleDatePicker,
  &>div{
    position: absolute;
  }
`
const Background = styled.div`
  background: rgba(0, 147, 198, .3);
  cursor: pointer;
  height: 100%;
  width: 100%;
`
const HeaderContainer = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  height: 15%;
`
const HelpersContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 10%;
  width: 100%;
`
const Helper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  padding: .5rem;
  width: 4rem;
  svg {
    fill: #ABB3B7;
    width: 100%;
  }
  &:hover,
  &:focus{
    svg {      
      fill: #0093C6;
    }
  }
`
const options = {
  distance: 3,
  keys: [
    'bank',
    'value',
    'type',
    'time',
    'date'
  ],
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.1
}

export default function TransactionHeader () {
  const [modal, setModal] = useState(null)
  const [barcode, setBarcode] = useState('')
  const [focusedInput, setFocusedInput] = useState('startDate')
  const dispatch = useDispatch()
  const { list: transactions, startDate, endDate } = useSelector(state => state.transactions)
  const { client } = useSelector(state => state.auth)
  const fuse = new Fuse(transactions, options)

  const search = query => {
    dispatch({ type: SORT_TRANSACTIONS })
    const result = fuse.search(query)
    if (result) {
      dispatch({ type: SORT_TRANSACTIONS_SUCCESS, payload: result })
    } else {
      dispatch({ type: SORT_TRANSACTIONS_ERROR, payload: 'No encontrada' })
    }
  }

  const getTransactions = (dates, type) => {
    if (type === 'range') {
      const { startDate: start, endDate: end } = dates
      if (start !== null && end !== null) {
        dispatch(fetchTransactions(client, start.toDate(), end.toDate()))
        setModal(null)
      }
    }
    if (type === 'single') {
      dispatch(fetchTransactions(client, dates.toDate(), dates.toDate()))
      setModal(null)
    }
  }

  const barcodeSearch = async () => {
    await sleep(1500)
    if (barcode.length > 12) {
      await dispatch(searchByBarcode(client, barcode))
      setModal(null)
    }
  }

  return (
    <HeaderContainer>
      <HelpersContainer>
        <DatesContainer>
          <p><strong>Desde:</strong>{` ${moment(startDate).format('DD-MM-YYYY')}`}</p>
          <p><strong>Hasta:</strong>{` ${moment(endDate).format('DD-MM-YYYY')}`}</p>
        </DatesContainer>
        <Field
          name='search'
          type='text'
          label='Buscar'
          onChange={e => {
            if (e.target.value.length > 3) {
              search(e.target.value)
            } else if (e.target.value.length === 0) {
              search('')
            }
          }}
        />
        <Helper onClick={() => setModal('barcodeSearch')}>
          <BarcodeIcon />
        </Helper>
        <Helper onClick={() => setModal('singleDate')}>
          <CalendarDayIcon />
        </Helper>
        <Helper onClick={() => setModal('dateRange')}>
          <CalendarIcon />
        </Helper>
        <Helper onClick={() => setModal('close')}>
          <DollarIcon />
        </Helper>
      </HelpersContainer>

      {
        modal === 'close' && (
          <RightModal trigger={setModal}>
            <CloseDate />
          </RightModal>
        )
      }
      {
        modal === 'dateRange' && (
          <ModalContainer>
            <Background onClick={() => setModal(null)} />
            <DayPickerRangeController
              startDate={moment(startDate)}
              endDate={moment(endDate)}
              onDatesChange={e => getTransactions(e, 'range')}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput || 'startDate')}
              numberOfMonths={2}
            />
          </ModalContainer>
        )
      }
      {
        modal === 'singleDate' && (
          <ModalContainer>
            <Background onClick={() => setModal(null)} />
            <DayPickerSingleDateController
              date={moment(startDate)}
              onDateChange={e => getTransactions(e, 'single')}
            />
          </ModalContainer>
        )
      }
      {
        modal === 'barcodeSearch' && (
          <ModalContainer>
            <Background onClick={() => setModal(null)} />
            <Field
              name='barcodesearch'
              type='text'
              label='Buscar por Código de Barras'
              onChange={e => {
                setBarcode(e.target.value)
                barcodeSearch()
              }}
            />
          </ModalContainer>
        )
      }
    </HeaderContainer>
  )
}
