import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { fetchBanks, fetchCodes, createTransaction } from '../../actions/transactionActions'
import { convertInNumber, formatCurrency } from '../../utils'
import Loading from '../components/Loading'
import Layout from '../components/Layout'
import TransactionsForm from '../components/TransactionsForm'
import Menu from '../components/Menu'
import styled from '@emotion/styled'
import Field from '../ui/Forms/Field'
import SendIcon from '../ui/icons/Send'

document.onkeypress = function (event) {
  event = (event || window.event)
  if (event.keyCode === 123) {
    return false
  }
}
document.onmousedown = function (event) {
  event = (event || window.event)
  if (event.keyCode === 123) {
    return false
  }
}
document.onkeydown = function (event) {
  event = (event || window.event)
  if (event.keyCode === 123) {
    return false
  }
}

const NewTransactionContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 100vh;
`
const TransactionForm = styled.form`
  padding: 5rem;
  width: 65%;
  overflow-y: scroll;
`
const TransactionStep = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  & span{
    margin-bottom: 2rem;
    width: 100%;
    font-size: 1.4rem;
    color: #565C5E;
    text-transform: uppercase;
  }
`
const Transactiontype = styled.div`
  display: flex;
  &>div {
    width: 33.3%;    
  }
`
const Totals = styled.div`
  width: 20%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & div{
    height: calc(100vh / 3);
  }
`
const Total = styled.div`
  width: 100%;
  text-transform: uppercase;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4{
    text-align: center;
    margin: 0;
    font-weight: 300;
    vertical-align: bottom;
  }
  p{
    background-color: transparent;
    margin: 0;
    border: 0px;
    color: #0093C6;
    font-size: 4rem;
    text-align: center;
    width: 100%;
    font-weight: 600;
    vertical-align: top;
  }
  &.missingMoney{
  background-color: #E31937;
  color: #fff;
    p{
      color: #fff;
    }
  }
  &.ok{
    background-color: #0093C6;
    color: #fff;
    p{
      color: #fff;
    }
  }
  &.singleTotal{
    height: 100%;
    background-color: #0093C6;
    color: #fff;
    p,
    h4{
      color: #fff;
    }
  }
`

const MoneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 30rem;
  & div{    
    margin: 1%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & label {
      width: 8rem;
      font-size: 2rem;
      margin-right: 2rem;
    }
    & input {
      text-align: center;
    }
  }
`
const SubmitButton = styled.button`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background-color: #fff;
  border: 0;  
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  border-radius: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  & svg {
    width: 60%;
    fill: #0093C6;
  }
  &:hover,
  &:focus{
    box-shadow: 0 0 1rem #fff;
    outline: 0;
  }
  &:disabled{
    background-color: #ABB3B7;
    color: #565C5E;
    box-shadow: none;
    cursor: auto;
    & svg {
      fill: #565C5E;
    }
  }
`
const renderTransactionType = (register, selected, reset) => (
  <TransactionStep>
    <span>Tipo de transacción</span>
    <Transactiontype>
      <Field
        type='radio'
        name='type'
        value='Recaudo'
        selected={selected}
        reference={register}
        onChange={(e) => {
          reset({
            type: 'Recaudo',
            transactions: [{
              value: '',
              bank: ''
            }],
            money: [0, 0, 0, 0, 0, 0, 0, 0]
          })
        }}
      />
      <Field
        type='radio'
        name='type'
        value='Retiro'
        selected={selected}
        reference={register}
        onChange={(e) => {
          reset({
            type: 'Retiro',
            transactions: [{
              value: '',
              bank: ''
            }]
          })
        }}
      />
      <Field
        type='radio'
        name='type'
        value='Deposito'
        selected={selected}
        reference={register}
        onChange={(e) => {
          reset({
            type: 'Deposito',
            transactions: [{
              value: '',
              bank: ''
            }],
            money: [0, 0, 0, 0, 0, 0, 0, 0]
          })
        }}
      />
    </Transactiontype>
  </TransactionStep>
)
const calculateTransactionTotal = transactions => {
  const total = transactions.reduce((accumulator, transaction) => accumulator + convertInNumber(transaction.value) + convertInNumber(transaction.additional), 0)
  return total
}

const calculateTotalMoney = moneyAmounts => {
  const total = moneyAmounts.reduce((accumulator, money, i) => {    
    if (i === 0) {
      return accumulator + (convertInNumber(money.value) * 100000) || 0
    }
    if (i === 1) {
      return accumulator + (convertInNumber(money.value) * 50000) || 0
    }
    if (i === 2) {
      return accumulator + (convertInNumber(money.value) * 20000) || 0
    }
    if (i === 3) {
      return accumulator + (convertInNumber(money.value) * 10000) || 0
    }
    if (i === 4) {
      return accumulator + (convertInNumber(money.value) * 5000) || 0
    }
    if (i === 5) {
      return accumulator + (convertInNumber(money.value) * 2000) || 0
    }
    if (i === 6) {
      return accumulator + (convertInNumber(money.value) * 1000) || 0
    }
    if (i === 7) {
      return accumulator + convertInNumber(money.value) || 0
    }
    return accumulator
  }, 0)
  return total
}

const renderTransactionPayment = (selectedType, register) => {
  const moneyAmounts = [100000, 50000, 20000, 10000, 5000, 2000, 1000, 'coins']

  if (selectedType !== 'Retiro') {
    return (
      <TransactionStep>
        <span>Efectivo</span>
        <MoneyContainer>
          {
            moneyAmounts.map((field, index) => (
              <Field key={`${field}`} type='number' name={`money[${index}].value`} label={field !== 'coins' ? formatCurrency(field) : 'Moneda'} reference={register()} />
            ))
          }
        </MoneyContainer>
      </TransactionStep>
    )
  }
  return null
}

export default function NewTransaction (props) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const client = useSelector(state => state.auth.client)
  const loading = useSelector(state => state.transactions.loading)
  const banks = useSelector(state => state.transactions.banks)
  const codes = useSelector(state => state.transactions.codes)
  const [totalTransactions, setTotalTransactions] = useState(0)
  const [totalMoney, setTotalMoney] = useState(0)
  const [total, setTotal] = useState(0)
  const [submitting, setSubmitting] = useState(false)

  const {
    register,
    errors,
    handleSubmit,
    watch,
    control,
    unregister,
    reset,
    setValue
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    criteriaMode: 'all',
    defaultValues: {
      type: 'Recaudo',
      transactions: [{
        value: '',
        bank: ''
      }],
      money: [0, 0, 0, 0, 0, 0, 0, 0]
    }
  })
  const { fields, append, remove } = useFieldArray({ control, name: 'transactions' })
  useFieldArray({ control, name: 'money' })
  const selectedType = watch('type')
  const transactions = watch('transactions')
  const moneyAmounts = watch('money')

  useEffect(() => {
    if (!submitting) {
      dispatch(fetchBanks(client))
      dispatch(fetchCodes(client))
      setSubmitting(true)
    }    
    setTotalTransactions(formatCurrency(calculateTransactionTotal(transactions)))
    if (selectedType !== 'Retiro') {
      setTotalMoney(formatCurrency(calculateTotalMoney(moneyAmounts)))
    }    
    setTotal(convertInNumber(totalMoney) - convertInNumber(totalTransactions))
  },
  [
    dispatch,
    submitting,
    client,
    transactions,
    totalMoney,
    totalTransactions,
    moneyAmounts,
    selectedType
  ]
  )
  
  function save (data) {
    dispatch(createTransaction({ ...data, totalTransactions, totalMoney, total, client, user, codes }))
    reset({
      type: 'Recaudo',
      transactions: [{
        value: '',
        bank: ''
      }],
      money: [0, 0, 0, 0, 0, 0, 0, 0]
    })
  }
  if (loading) {
    return <Loading />
  }
  return (
    <Layout title='Nueva Transacción' {...props}>
      <NewTransactionContainer>
        <Menu />
        <TransactionForm onSubmit={handleSubmit(save)} noValidate >
          {
            renderTransactionType(register, selectedType, reset)
          }
          
          <TransactionsForm 
            type={selectedType}
            fields={fields}
            append={append}
            remove={remove}
            register={register}
            errors={errors}
            unregister={unregister}
            banks={banks}
            codeStandards={codes}
            setValue={setValue}
          />
          
          {
            renderTransactionPayment(selectedType, register)
          }
          <SubmitButton
            disabled={Object.keys(errors).length > 0 || (total < 0 && selectedType !== 'Retiro')}
            type='submit'
          ><SendIcon />
          </SubmitButton>
        </TransactionForm>
        <Totals id='totals'>
          {
            selectedType !== 'Retiro' ? (
              <>
                <Total>
                  <h4>Total Transacciones</h4>
                  <p>{formatCurrency(totalTransactions)}</p>
                </Total>
                <Total>
                  <h4>Total Efectivo</h4>
                  <p>{formatCurrency(totalMoney)}</p>
                </Total>
                <Total className={`${total < 0 ? 'missingMoney' : 'ok'}`}>
                  <h4>{total < 0 ? 'Faltan' : 'Puedes devolver'}</h4>
                  <p>{formatCurrency(total)}</p>
                </Total>
              </>
            ) : (
              <>
                <Total className='singleTotal'>
                  <h4>Total Transacciones</h4>
                  <p>{formatCurrency(totalTransactions)}</p>
                </Total>
              </>
            )
          }
        </Totals>
      </NewTransactionContainer>
    </Layout>
  )
}
