import React, { useState } from 'react'
import styled from '@emotion/styled'

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  label{
    padding-bottom: .2rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem;
  }
  input[type=password],
  input[type=email],
  input[type=text],
  input[type=number],
  select{  
    padding: 1.5rem;
    font-size: 1.8rem;
    color: #565C5E;
    border: 1px solid #ABB3B7;
    border-radius: .5rem;
    transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
    max-height: 5.4rem;
    line-height: 1.5;    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus{
      outline: none;
    }  
  }
  p{
    margin: .2rem;
    font-size: 1.2rem;
    height: 2rem;
  }
  ${props => (
    props.focus && (`
      color: #0093C6;
      input[type=password],
      input[type=email],
      input[type=text],
      input[type=number],
      select{
        color: #0093C6;
        &:focus{
          outline: none;
          border-color: #0093C6;
        }
      }
    `)
  )}
  ${props => (
    props.error && (`
      color: #E31937;
      input[type=password],
      input[type=email],
      input[type=text],
      input[type=number],
      select{
        color: #E31937;
        border-color: #E31937;
        &:focus{
          outline: none;          
        }
      }
      
    `)
  )}
`
const FieldRadioContainer = styled.div`
  text-align: center;
  label {
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 3rem;
    cursor: pointer;
    position: relative;
    transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
    & input{
      position: absolute;
      opacity: 0;
    }
    &.active{
      color: #0093C6;
    }
  }
  
`

export default function Field (props) {
  const [focus, setFocus] = useState(false)
  const [touched, setTouched] = useState(false)
  const {
    type,
    name,
    reference,
    value,
    selected,
    label,
    defautlValue,
    items,
    onChange,
    error
  } = props

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    case 'number':
      return (
        <FieldContainer
          {...props}
          focus={focus}
          touched={touched}
          onFocus={() => {
            setFocus(true)
            setTouched(true)
          }}
          onBlur={() => setFocus(false)}
          error={error}
        >
          <label> {label} </label>
          <input autoComplete='off' type={type} name={name} ref={reference} defautlvalue={defautlValue} onChange={onChange} />
          {error && <p>{error.message}</p>}
        </FieldContainer>
      )
    case 'radio':
      return (
        <FieldRadioContainer {...props}>
          <label className={selected === value ? 'active' : null}>
            <input type={type} name={name} value={value} ref={reference} onChange={onChange} />
            {value}
          </label>
        </FieldRadioContainer>
      )
    case 'select':
      return (
        <FieldContainer
          {...props}
          focus={focus}
          touched={touched}
          onFocus={() => {
            setFocus(true)
            setTouched(true)
          }}
          onBlur={() => setFocus(false)}
          error={error}
        >
          <label> {label} </label>
          <select autoComplete='off' type={type} name={name} ref={reference} defautlvalue={defautlValue} onChange={onChange}>
            <option value=''>Selecciona</option>
            {items.map(item => <option key={item.value} value={item.value}>{item.display}</option>)}
          </select>
          {error && <p>{error.message}</p>}
        </FieldContainer>
      )
    case 'currency':
      return (
        <FieldContainer
          {...props}
          focus={focus}
          touched={touched}
          onFocus={() => {
            setFocus(true)
            setTouched(true)
          }}
          onBlur={() => setFocus(false)}
          error={error}
        >
          <label> {label} </label>
          <input autoComplete='off' type='text' pattern='^\$\d{1,3}(,\d{3})*(\.\d+)?$' name={name} ref={reference} defautlvalue={defautlValue} onChange={onChange} />
          {error && <p>{error.message}</p>}
        </FieldContainer>
      )
    default:
      return <p>Tipo no encontrado</p>
  }
}
