import React from 'react'
import styled from '@emotion/styled'

const ButtonContainer = styled.button`
  width: 100%;
  padding: 1.6rem;
  border: 0;
  border-radius: .5rem;
  text-transform: uppercase;
  background-color: #0093C6;
  color: #fff;
  cursor: pointer;
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  &:focus{
    outline-color: #00FFC6
  }
  &:disabled{
    background-color: #FBFCF8;
    color: #ABB3B7;
    cursor: default;
    &:hover,
    &:focus{
      background-color: transparent;
    }
  }
`

export default function Button (props) {
  return (
    <ButtonContainer {...props}>
      {props.children}
    </ButtonContainer>
  )
}
