import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import Field from '../../ui/Forms/Field'
import Button from '../../ui/Forms/Button'
import CloseIcon from '../../ui/icons/Close'
import { formatCurrency } from '../../../utils'
import { updateTransaction } from '../../../actions/transactionActions'

const TransactionUpdateContainer = styled.section`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100vh;  
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`
const Background = styled.section`
  background: rgba(0, 147, 198, .3);
  cursor: pointer;
  height: 100%;
  width: 100%;
`

const TransactionForm = styled.form`
  background: #fff;
  box-shadow: 0 .4rem 1rem rgba(0,185,249,.9);
  height: 100%;
  padding: 2rem;
  position: absolute;
  width: 30%;
  h2 {
    color: #565C5E;
  }
`

const CloseTrigger = styled.div`
  position: absolute;
  width: 3rem;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  svg {
    width: 100%;
    fill: #565C5E;
  }
  &:hover,
  &:focus{
    svg{
      fill: #0093C6;
    }
  }
`

export default function TransactionUpdate ({ transaction, trigger }) {
  const dispatch = useDispatch()
  const { client } = useSelector(state => state.auth)
  const { banks, list: transactions } = useSelector(state => state.transactions)
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      value: formatCurrency(transaction.value),
      bank: transaction.bank
    }
  })

  const saveTransaction = data => {
    dispatch(updateTransaction(client, transaction.id, data, transactions))
    trigger(null)
  }

  return (
    <TransactionUpdateContainer>
      <Background onClick={() => trigger(null)} />
      <TransactionForm onSubmit={handleSubmit(saveTransaction)}>
        <h2>Actualizar Transacción</h2>
        <Field
          type='currency'
          name='value'
          label='Valor'
          onChange={e => {
            setValue('value', formatCurrency(e.target.value))
          }}
          reference={register({ required: 'Campo Requerido' })}
          error={errors.value && errors.value}
        />
        <Field
          type='select'
          name='bank'
          label='Banco'
          items={banks.map(bank => ({ display: bank.name, value: bank.id }))}
          reference={register({ required: 'Campo Requerido' })}
          error={errors.bank && errors.bank}
        />
        <Button type='submit'>Guardar</Button>
        <CloseTrigger onClick={() => trigger(null)}>
          <CloseIcon />
        </CloseTrigger>
      </TransactionForm>
    </TransactionUpdateContainer>
  )
}
