import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTransactions, fetchBanks } from '../../actions/transactionActions'
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import TransactionHeader from '../components/TransactionHeader'
import TransactionList from '../components/TransactionList'
import Loading from '../components/Loading'

const TransactionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  width: 70%;
  height: 100vh;  
`
const Container = styled.div`
  display: flex;
`

export default function NewTransaction (props) {
  const dispatch = useDispatch()
  const { client } = useSelector(state => state.auth)
  const { banks, list, loading } = useSelector(state => state.transactions)

  useEffect(() => {
    dispatch(fetchTransactions(client))
    dispatch(fetchBanks(client))
  }, [dispatch, client])

  if (loading) {
    return <Loading />
  }
  return (
    <Layout title='Transacciones' {...props}>
      <Container>
        <Menu />
        <TransactionListContainer>
          <TransactionHeader />
          <TransactionList transactions={list} banks={banks} />
        </TransactionListContainer>
      </Container>
    </Layout>
  )
}
