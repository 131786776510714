export const convertInNumber = number => {
  if (!number) return 0
  if (typeof number === 'string') return Number(number.replace(/[$,\s]/g, ''))
  return number
}
export const findAmount = (code, codeStandards) => {
  //  Extract ID from typed code
  const id = code.substring(0, 16)
  //  Check if exists
  const codeReference = codeStandards.filter(code => code.id === id)
  if (codeReference.length > 0) {
    //  Get Start and End amount position
    const start = codeReference[0].priceLength.start
    const end = codeReference[0].priceLength.end
    if (start && end) {
      const amountType = code.substring(start - 4, start)
      if (amountType === '3902') {
        const intAmount = code.substring(start, end - 2)
        const decAmount = code.substring(end - 2, end)
        const amount = convertInNumber(`${intAmount}.${decAmount}`)
        return amount
      } else if (amountType === '3900') {
        return convertInNumber(code.substring(start, end))
      }
    }
  }
  return 0
}
export const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const formatCurrency = (value) => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.
  // get input value
  let inputValue = value ? value.toString() : '0'

  // don't validate empty input
  if (inputValue === '') { return }

  // initial caret position
  // let caret_pos = input.prop("selectionStart");

  // check for decimal
  if (inputValue.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimalPos = inputValue.indexOf('.')

    // split number by decimal point
    let leftSide = inputValue.substring(0, decimalPos)
    let rightSide = inputValue.substring(decimalPos)

    // add commas to left side of number
    leftSide = formatNumber(leftSide)

    // validate right side
    rightSide = formatNumber(rightSide)

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, 2)

    // join number by .
    inputValue = '$' + leftSide + '.' + rightSide
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputValue = formatNumber(inputValue)
    inputValue = '$' + inputValue
  }

  // send updated string to input
  return inputValue
}
export const codeData = (code, codeStandards) => {
  const id = code.substring(0, 16)
  const codeReference = codeStandards.filter(code => code.id === id)
  if (codeReference.size) {
    const invoiceAmount = code.substring(codeReference.get(0).get('priceLength').get('start'), codeReference.get(0).get('priceLength').get('end'))
    const invoiceReference = code.substring(codeReference.get(0).get('referenceLength').get('start'), codeReference.get(0).get('referenceLength').get('end'))
    const invoiceId = code.substring(codeReference.get(0).get('idLength').get('start'), codeReference.get(0).get('idLength').get('end'))
    if (codeReference.get(0).get('paymentDateLength')) {
      const toSendDate = code.substring(codeReference.get(0).get('paymentDateLength').get('start'), codeReference.get(0).get('paymentDateLength').get('end'))
      const invoicePaymentDate = new Date(toSendDate.substring(0, 4), toSendDate.substring(4, 6) - 1, toSendDate.substring(6, 8))
      return {
        invoiceAmount: Number(invoiceAmount),
        invoiceReference,
        invoiceId,
        invoicePaymentDate
      }
    }
    return {
      invoiceAmount: Number(invoiceAmount),
      invoiceReference,
      invoiceId,
      invoicePaymentDate: ''
    }
  }
  return null
}
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
