import React, { useEffect, useRef } from 'react'
import CloseIcon from '../../ui/icons/Close'
import styled from '@emotion/styled'
import Field from '../../ui/Forms/Field'
import { findAmount, convertInNumber, formatCurrency } from '../../../utils'

const TransactionStep = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  & span{
    margin-bottom: 2rem;
    width: 100%;
    font-size: 1.4rem;
    color: #565C5E;
    text-transform: uppercase;
  }
`
const Transaction = styled.div`
  display: flex;
  &:first-of-type{
    margin-top: 2rem;
    button:first-of-type{
      display: none;
    }
  }
  margin: 1.5rem 0;
  input,
  select{
    min-height: 0;
  }
`
const TransactionId = styled.div`
  display: flex;
  width: 8.5rem;
  justify-content: center;
  align-items: center;
  background-color: #0093C6;
  color: #fff;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  font-size: 4rem;
  font-weight: 600;
`
const TransactionContainer = styled.div`
  position: relative;
  background-color: rgba(0, 147, 198, .1);
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 8.5rem);
  &.two-fields{
    div{
      width: calc(100% / 2 - 2%)
    }
  }
  div{
    margin: 1%;
    width: calc(100% / 3 - 2%)    
  }
  div.code{
    width: 100%;
  }
  div:last-of-type{
    margin-right: 0;
  }
`
const LessButton = styled.button`
  position: absolute;
  right: -1.5rem;
  top: -1.5rem;
  color: #fff;
  background-color: #0093C6;
  border: 0;
  border-radius: 3.5rem;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1rem rgba(0,185,249,.8);
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  &:hover,
  &:focus{
    outline: 0;
    background-color: #E31937;
    box-shadow: 0 0 1rem #E31937;
  }
  svg{
    width: 1.5rem;
    height: 1.5rem;
    fill: #fff;
  }
`
const AddTransaction = styled.button`
  width: 100%;
  background-color: #0093C6;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  font-weight: 600;
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  &:hover,
  &:focus{
    background-color: #00b9f9;
    box-shadow: 0 .4rem 1rem rgba(0,185,249,.5);
  }
`

const calculateTransactionAdditional = amount => {
  if (convertInNumber(amount) > 0 && convertInNumber(amount) < 200000) return 100
  if (convertInNumber(amount) > 200000 && convertInNumber(amount) < 300000) return 500
  if (convertInNumber(amount) > 300000) return 1000
}

export default function TransactionForm({type, fields, append, remove, register, errors, unregister, banks, codeStandards, setValue}) {
  const appendButtonRef = useRef(null)

  const scrollToAppendButton = () =>{
    appendButtonRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(scrollToAppendButton, [fields])

  return (
    <TransactionStep>
      <span>Transacciones</span>
      {
        type === 'Recaudo' && (
          fields.map(
            (field, index) => (
              <Transaction key={`${field.id}`}>
                <TransactionId>{index + 1}</TransactionId>
                <TransactionContainer>
                  <Field
                    className='code'
                    type='text'
                    name={`transactions[${index}].code`}
                    label='Código'
                    defaultValue={`${field.code}`}
                    onChange={e => {
                      setValue(`transactions[${index}].value`, formatCurrency(findAmount(e.target.value, codeStandards)))
                      setValue(`transactions[${index}].additional`, formatCurrency(calculateTransactionAdditional(findAmount(e.target.value, codeStandards))))
                    }}
                    reference={register()}
                    error={errors.transactions && errors.transactions[index] && errors.transactions[index].code}
                  />
                  <Field
                    type='currency'
                    name={`transactions[${index}].value`}
                    label='Valor'
                    defaultValue={`${field.value}`}
                    onChange={e => {
                      setValue(`transactions[${index}].value`, formatCurrency(e.target.value))
                      setValue(`transactions[${index}].additional`, formatCurrency(calculateTransactionAdditional(e.target.value)))
                    }}
                    reference={register({ required: 'Campo Requerido' })}
                    error={errors.transactions && errors.transactions[index] && errors.transactions[index].value}
                  />
                  <Field
                    type='currency'
                    name={`transactions[${index}].additional`}
                    label='Adicional'
                    defaultValue={`${field.additional}`}
                    onChange={e => {
                      setValue(`transactions[${index}].additional`, formatCurrency(e.target.value))
                    }}
                    reference={register()}
                    error={errors.transactions && errors.transactions[index] && errors.transactions[index].additional}
                  />
                  <Field
                    type='select'
                    name={`transactions[${index}].bank`}
                    label='Banco'
                    items={banks.map(bank => ({ display: bank.name, value: bank.id }))}
                    defaultValue={`${field.bank}`}
                    reference={register({ required: 'Campo Requerido' })}
                    error={errors.transactions && errors.transactions[index] && errors.transactions[index].bank}
                  />
                  <LessButton type='button' onClick={() => { remove(index) }}> <CloseIcon /> </LessButton>
                </TransactionContainer>
              </Transaction>
            )
          )
        )
      }
      {
        (type === 'Retiro' || type === 'Deposito') && (
          fields.map(
            (field, index) => {
              unregister(`transactions[${index}].code`)
              unregister(`transactions[${index}].additional`)
              return (
                <Transaction key={`${field.id}`}>
                  <TransactionId>{index + 1}</TransactionId>
                  <TransactionContainer className='two-fields'>
                    <Field
                      type='text'
                      name={`transactions[${index}].value`}
                      label='Valor'
                      fieldArray='transactions'
                      onChange={e => {
                        setValue(`transactions[${index}].value`, formatCurrency(e.target.value))
                      }}
                      defaultValue={`${field.value}`}
                      reference={register({ required: 'Campo Requerido' })}
                      error={errors.transactions && errors.transactions[index] && errors.transactions[index].value}
                    />
                    <Field
                      type='select'
                      name={`transactions[${index}].bank`}
                      label='Banco'
                      items={banks.map(bank => ({ display: bank.name, value: bank.id }))}
                      defaultValue={`${field.bank}`}
                      reference={register({ required: 'Campo Requerido' })}
                      error={errors.transactions && errors.transactions[index] && errors.transactions[index].bank}
                    />
                    <LessButton type='button' onClick={() => { remove(index) }}> <CloseIcon /> </LessButton>
                  </TransactionContainer>
                </Transaction>
              )
            }
          )
        )
      }
      <AddTransaction
        ref={appendButtonRef}
        onClick={() => {
          append()
        }}
      >Agregar
      </AddTransaction>
    </TransactionStep>
  )
  
}