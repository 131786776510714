import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { loginWithUserAndPassword } from '../../actions/authActions'
import { Redirect } from 'react-router-dom'
import Layout from '../components/Layout'
import LogoIcon from '../ui/icons/Logo'
import Field from '../ui/Forms/Field'
import Button from '../ui/Forms/Button'

const LoginContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  p{
    color: #E31937;
    margin: .2rem;
    font-size: 1.5rem;
    height: 2rem;
  }
`
const LogoContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  svg{
    width: 20%;
    height: auto;
  }
`
const LoginForm = styled.form`
  width: 15%;
`

export default function Login () {
  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: 'all',
    mode: 'onBlur'
  })

  const dispatch = useDispatch()
  const loading = useSelector(state => state.auth.loading)
  const error = useSelector(state => state.auth.error)
  const user = useSelector(state => state.auth.user)
  const client = useSelector(state => state.auth.client)

  const login = ({ email, password }) => {
    dispatch(loginWithUserAndPassword(email, password))
  }

  if (user && client && !error) {
    return <Redirect to='/' />
  }

  return (
    <Layout title='Login'>
      <LoginContainer>
        <LogoContainer href='https://www.corenovo.com/' target='_blank'>
          <LogoIcon />
        </LogoContainer>
        <LoginForm onSubmit={handleSubmit(login)}>
          <Field
            type='email'
            name='email'
            label='Email'
            reference={register({
              required: 'El Correo es requerido.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Correo invalido'
              }
            })}
            error={errors.email && errors.email}
          />
          <Field
            type='password'
            name='password'
            label='Contraseña'
            reference={register({
              required: 'La contraseña es requerida.',
              minLength: {
                value: 6,
                message: 'Debe contener mínimo 6 caracteres.'
              }
            })}
            error={errors.password && errors.password}
          />
          <Button disabled={Object.keys(errors).length > 0 || loading} type='submit'>
            {loading ? 'Cargando' : 'Iniciar Sesión'}
          </Button>
        </LoginForm>
        {error && error !== 'No permitido.' && <p>{error}</p>}
      </LoginContainer>
    </Layout>
  )
}
