import firebase from '../firebase'
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT
} from './types'

export function loginWithUserAndPassword (email, password) {
  return async dispatch => {
    dispatch({ type: LOGIN })
    try {
      const { user } = await firebase.login(email, password)
      const userDetail = await firebase.db.collection('users').doc(user.uid).get()
      const clientDetail = await firebase.db.collection('clients').doc(userDetail.data().client).get()
      dispatch({ type: LOGIN_SUCCESS, payload: { user, client: { id: clientDetail.id, ...clientDetail.data() } } })
    } catch (error) {
      console.log(error)
      dispatch({ type: LOGIN_ERROR, payload: { error: 'Usuario o Contraseña incorrectos.' } })
    }
  }
}

export function checkAuth () {
  return async dispatch => {
    dispatch({ type: LOGIN })
    await firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        try {
          const userDetail = await firebase.db.collection('users').doc(user.uid).get()
          const clientDetail = await firebase.db.collection('clients').doc(userDetail.data().client).get()
          dispatch({ type: LOGIN_SUCCESS, payload: { user, client: { id: clientDetail.id, ...clientDetail.data() } } })
        } catch (error) {
          console.log(error)
          dispatch({ type: LOGIN_ERROR, payload: { error: 'No permitido.' } })
        }
      } else {
        dispatch({ type: LOGIN_ERROR, payload: { error: 'No permitido.' } })
      }
    })
  }
}

export function logOut () {
  return async dispatch => {
    await firebase.logout()
    dispatch({ type: LOGOUT })
  }
}
