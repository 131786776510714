import React from 'react'

function BarcodeIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 -40 512 512'
    >
      <path d='M456.832 0H55.168C24.746 0 0 24.746 0 55.168V376.5c0 30.422 24.746 55.168 55.168 55.168h401.664c30.422 0 55.168-24.746 55.168-55.168V55.168C512 24.746 487.254 0 456.832 0zM482 376.5c0 13.879-11.29 25.168-25.168 25.168H55.168C41.289 401.668 30 390.378 30 376.5V55.168C30 41.289 41.29 30 55.168 30h401.664C470.711 30 482 41.29 482 55.168zm0 0' />
      <path d='M416.668 80.336H95.332c-8.281 0-15 6.715-15 15 0 8.281 6.719 15 15 15h321.336c8.281 0 15-6.719 15-15 0-8.285-6.719-15-15-15zm0 0M111.398 160.668c-8.28 0-15 6.715-15 15v160.668c0 8.281 6.72 15 15 15 8.286 0 15-6.719 15-15V175.668c0-8.285-6.714-15-15-15zm0 0M256 160.668c-8.285 0-15 6.715-15 15v160.668c0 8.281 6.715 15 15 15s15-6.719 15-15V175.668c0-8.285-6.715-15-15-15zm0 0M400.602 160.668c-8.286 0-15 6.715-15 15v160.668c0 8.281 6.714 15 15 15 8.28 0 15-6.719 15-15V175.668c0-8.285-6.72-15-15-15zm0 0M191.734 160.668h-16.066c-8.285 0-15 6.715-15 15v160.668c0 8.281 6.715 15 15 15h16.066c8.282 0 15-6.719 15-15V175.668c0-8.285-6.714-15-15-15zm0 0M336.332 160.668h-16.066c-8.282 0-15 6.715-15 15v160.668c0 8.281 6.718 15 15 15h16.066c8.285 0 15-6.719 15-15V175.668c0-8.285-6.715-15-15-15zm0 0' />
    </svg>
  )
}

export default BarcodeIcon
