import React from 'react'
import styled from '@emotion/styled'
import CloseIcon from '../../ui/icons/Close'

const ModalContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100vh;  
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`
const Background = styled.div`
  background: rgba(0, 147, 198, .3);
  cursor: pointer;
  height: 100%;
  width: 100%;
`

const ModalContent = styled.div`
  background: #fff;
  box-shadow: 0 .4rem 1rem rgba(0,185,249,.9);
  height: 100%;
  padding: 2rem;
  position: absolute;
  width: 30%;
  overflow-y: scroll;
  h2 {
    color: #565C5E;
  }
`

const CloseTrigger = styled.div`
  position: absolute;
  width: 3rem;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  svg {
    width: 100%;
    fill: #565C5E;
  }
  &:hover,
  &:focus{
    svg{
      fill: #0093C6;
    }
  }
`

export default function TransactionUpdate ({ children, trigger }) {
  return (
    <ModalContainer>
      <Background onClick={() => trigger(null)} />
      <ModalContent>
        {children}
        <CloseTrigger onClick={() => trigger(null)}>
          <CloseIcon />
        </CloseTrigger>
      </ModalContent>
    </ModalContainer>
  )
}
