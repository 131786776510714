import moment from 'moment'
import Swal from 'sweetalert2'
import firebase from '../firebase'
import { convertInNumber, codeData } from '../utils'
import {
  FETCH_BANKS,
  FETCH_BANKS_ERROR,
  FETCH_BANKS_SUCCESS,
  FETCH_CODES,
  FETCH_CODES_ERROR,
  FETCH_CODES_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_SUCCESS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_ERROR,
  FETCH_TRANSACTIONS_SUCCESS,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_ERROR,
  UPDATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_ERROR,
  DELETE_TRANSACTION_SUCCESS,
  CLOSE_DATE,
  CLOSE_DATE_ERROR,
  CLOSE_DATE_SUCCESS,
  SET_START_DATE,
  SET_END_DATE,
  SORT_TRANSACTIONS,
  SORT_TRANSACTIONS_ERROR,
  SORT_TRANSACTIONS_SUCCESS
} from './types'

export function fetchBanks (client) {
  return async dispatch => {
    dispatch({ type: FETCH_BANKS })
    let banks = []
    try {
      const response = await firebase.db.collection('clients').doc(client.id).collection('banks').get()
      response.forEach(
        bank => {
          const innerBank = bank.data()
          innerBank.id = bank.id
          banks = [...banks, innerBank]
        }
      )
      dispatch({ type: FETCH_BANKS_SUCCESS, payload: banks })
    } catch (error) {
      console.log(error)
      dispatch({ type: FETCH_BANKS_ERROR, payload: error })
    }
  }
}
export function fetchCodes (client) {
  return async dispatch => {
    dispatch({ type: FETCH_CODES })
    let codes = []
    try {
      const response = await firebase.db.collection('clients').doc(client.id).collection('codes').get()
      response.forEach(
        code => {
          const innerCode = code.data()
          innerCode.id = code.id
          codes = [...codes, innerCode]
        }
      )
      dispatch({ type: FETCH_CODES_SUCCESS, payload: codes })
    } catch (error) {
      console.log(error)
      dispatch({ type: FETCH_CODES_ERROR, payload: error })
    }
  }
}
export function createTransaction (transaction) {
  return async dispatch => {
    dispatch({ type: CREATE_TRANSACTION })
    try {
      const { client, user, type, transactions, codes } = transaction

      switch (type) {
        case 'Deposito':
          await transactions.forEach(
            async item => {
              await firebase.db
                .collection('clients')
                .doc(client.id)
                .collection('transactions')
                .add({
                  type,
                  value: convertInNumber(item.value),
                  bank: item.bank,
                  createdAt: new Date(),
                  byId: user.uid,
                  byName: user.displayName || '',
                  verified: false
                })
            }
          )
          Swal.fire({ title: 'Creadas Correctamente', icon: 'success', showConfirmButton: false, timer: 1000 })
          return dispatch({ type: CREATE_TRANSACTION_SUCCESS })
        case 'Retiro':
          await transactions.forEach(
            async item => {
              await firebase.db
                .collection('clients')
                .doc(client.id)
                .collection('transactions')
                .add({
                  type,
                  value: convertInNumber(item.value),
                  bank: item.bank,
                  createdAt: new Date(),
                  byId: user.uid,
                  byName: user.displayName || '',
                  verified: false
                })
            }
          )
          Swal.fire({ title: 'Creadas Correctamente', icon: 'success', showConfirmButton: false, timer: 1000 })
          return dispatch({ type: CREATE_TRANSACTION_SUCCESS })
        case 'Recaudo':
          await transactions.forEach(
            async item => {
              const data = codeData(item.code, codes)
              await firebase.db
                .collection('clients')
                .doc(client.id)
                .collection('transactions')
                .add({
                  code: item.code || '',
                  type,
                  additional: convertInNumber(item.additional) || 0,
                  value: convertInNumber(item.value),
                  bank: item.bank,
                  createdAt: new Date(),
                  byId: user.uid,
                  byName: user.displayName || '',
                  verified: false,
                  invoiceAmount: data ? data.invoiceAmount : '',
                  invoiceId: data ? data.invoiceId : '',
                  invoicePaymentDate: data ? data.invoicePaymentDate : '',
                  invoiceReference: data ? data.invoiceReference : ''
                })
            }
          )
          Swal.fire({ title: 'Creadas Correctamente', icon: 'success', showConfirmButton: false, timer: 1000 })
          return dispatch({ type: CREATE_TRANSACTION_SUCCESS })
        default:
          return null
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: CREATE_TRANSACTION_ERROR, payload: error })
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error',
        text: 'Hubo un error intenta de nuevo.'
      })
    }
  }
}
export function fetchTransactions (client, startDate, endDate) {
  return async dispatch => {
    dispatch({ type: FETCH_TRANSACTIONS })
    try {
      let start
      let end
      let transactions = []
      if (!startDate && !endDate) {
        start = moment(`${moment().format('YYYY-MM-DD')} 00:00:00`).toDate()
        end = moment(`${moment().format('YYYY-MM-DD')} 23:59:59`).toDate()
      } else {
        start = moment(`${moment(startDate).format('YYYY-MM-DD')} 00:00:00`).toDate()
        end = moment(`${moment(endDate).format('YYYY-MM-DD')} 23:59:59`).toDate()
      }
      dispatch({ type: SET_START_DATE, payload: start })
      dispatch({ type: SET_END_DATE, payload: end })
      const response = await firebase.db.collection('clients').doc(client.id)
        .collection('transactions')
        .orderBy('createdAt', 'asc')
        .startAt(start)
        .endAt(end)
        .get()
      response.forEach(
        transaction => {
          const innerTransaction = transaction.data()
          innerTransaction.id = transaction.id
          innerTransaction.date = moment(innerTransaction.createdAt.toDate()).format('DD/MM/YYYY')
          innerTransaction.time = moment(innerTransaction.createdAt.toDate()).format('hh:mm A')
          transactions = [...transactions, innerTransaction]
        }
      )
      dispatch({ type: FETCH_TRANSACTIONS_SUCCESS, payload: transactions })
    } catch (error) {
      console.log(error)
      dispatch({ type: FETCH_TRANSACTIONS_ERROR, payload: error })
    }
  }
}
export function updateTransaction (client, id, data, transactions) {
  return async dispatch => {
    dispatch({ type: UPDATE_TRANSACTION })
    try {
      const innerTransactions = transactions
      const transaction = await firebase.db.collection('clients').doc(client.id).collection('transactions').doc(id)
      if (data.value) {
        data.value = convertInNumber(data.value)
      }
      if (data.additional) {
        data.additional = convertInNumber(data.additional)
      }
      await transaction.update(data)
      const index = transactions.findIndex(transaction => transaction.id === id)
      innerTransactions[index] = { ...innerTransactions[index], ...data }
      dispatch({ type: UPDATE_TRANSACTION_SUCCESS, payload: innerTransactions })
      Swal.fire({ title: 'Actualizada Correctamente', icon: 'success', showConfirmButton: false, timer: 800 })
    } catch (error) {
      console.log(error)
      dispatch({ type: UPDATE_TRANSACTION_ERROR, payload: error })
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error',
        text: 'Hubo un error intenta de nuevo.'
      })
    }
  }
}
export function deleteTransaction (client, id, transactions) {
  return async dispatch => {
    dispatch({ type: DELETE_TRANSACTION })
    try {
      await firebase.db.collection('clients').doc(client.id).collection('transactions').doc(id).delete()
      const innerTransactions = transactions.filter(transaction => transaction.id !== id)
      dispatch({ type: DELETE_TRANSACTION_SUCCESS, payload: innerTransactions })
      Swal.fire({ title: 'Eliminada Correctamente', icon: 'success', showConfirmButton: false, timer: 800 })
    } catch (error) {
      console.log(error)
      dispatch({ type: DELETE_TRANSACTION_ERROR, payload: error })
      Swal.fire({
        icon: 'error',
        title: 'Hubo un error',
        text: 'Hubo un error intenta de nuevo.'
      })
    }
  }
}
export function closeDate (banks, transactions) {
  return async dispatch => {
    dispatch({ type: CLOSE_DATE })
    try {
      const bankTotals = {}
      let additionalTotal = 0

      banks.map(
        bank => {
          const totals = bankTotals[bank.id] = {
            name: bank.name,
            withdrawal: {
              quantity: 0,
              amount: 0
            },
            deposit: {
              quantity: 0,
              amount: 0
            },
            collection: {
              quantity: 0,
              amount: 0
            }
          }
          return totals
        }
      )

      transactions.forEach(
        transaction => {
          if (transaction.type === 'Retiro') {
            bankTotals[transaction.bank].withdrawal.quantity += 1
            bankTotals[transaction.bank].withdrawal.amount += convertInNumber(transaction.value)
          }
          if (transaction.type === 'Deposito') {
            bankTotals[transaction.bank].deposit.quantity += 1
            bankTotals[transaction.bank].deposit.amount += convertInNumber(transaction.value)
          }
          if (transaction.type === 'Recaudo') {
            bankTotals[transaction.bank].collection.quantity += 1
            bankTotals[transaction.bank].collection.amount += convertInNumber(transaction.value)
            additionalTotal += convertInNumber(transaction.additional)
          }
        }
      )
      dispatch({ type: CLOSE_DATE_SUCCESS, payload: { banks: bankTotals, additional: additionalTotal } })
    } catch (error) {
      console.log(error)
      dispatch({ type: CLOSE_DATE_ERROR, payload: error })
    }
  }
}
export function searchByBarcode (client, code) {
  return async dispatch => {
    dispatch({ type: SORT_TRANSACTIONS })
    try {
      let transactions = []
      const response = await firebase.db.collection('clients').doc(client.id).collection('transactions').where('code', '==', code).get()
      if (!response.empty) {
        response.forEach(
          transaction => {
            const innerTransaction = transaction.data()
            innerTransaction.id = transaction.id
            innerTransaction.date = moment(innerTransaction.createdAt.toDate()).format('DD/MM/YYYY')
            innerTransaction.time = moment(innerTransaction.createdAt.toDate()).format('hh:mm A')
            transactions = [...transactions, { item: innerTransaction }]
          }
        )
        dispatch({ type: SORT_TRANSACTIONS_SUCCESS, payload: transactions })
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: SORT_TRANSACTIONS_ERROR, payload: error })
    }
  }
}
