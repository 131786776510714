import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { checkAuth } from '../actions/authActions'
import Loading from './components/Loading'
import Login from './pages/Login'
import NewTransaction from './pages/NewTransaction'
import Transactions from './pages/Transactions'

function PrivateRoute ({ children, ...rest }) {
  const { client, user, error, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user && !client && !loading) {
      dispatch(checkAuth())
    }
  }, [dispatch, client, user, loading])

  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props
        if (user && client && !error) {
          return children
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }
      }}
    />
  )
}

export default function App () {
  const { loading } = useSelector(state => state.auth)
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <Switch>
        <PrivateRoute exact path='/'>
          <Transactions />
        </PrivateRoute>
        <Route exact path='/login' component={Login} />
        <PrivateRoute exact path='/transacciones'>
          <Transactions />
        </PrivateRoute>
        <PrivateRoute exact path='/transacciones/nueva'>
          <NewTransaction />
        </PrivateRoute>
      </Switch>
    </>
  )
}
