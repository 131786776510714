export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'

export const FETCH_CODES = 'FETCH_CODES'
export const FETCH_CODES_SUCCESS = 'FETCH_CODES_SUCCESS'
export const FETCH_CODES_ERROR = 'FETCH_CODES_ERROR'

export const FETCH_BANKS = 'FETCH_BANKS'
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS'
export const FETCH_BANKS_ERROR = 'FETCH_BANKS_ERROR'

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS'
export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR'

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS'
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR'

export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_ERROR = 'UPDATE_TRANSACTION_ERROR'

export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR'

export const SORT_TRANSACTIONS = 'SORT_TRANSACTIONS'
export const SORT_TRANSACTIONS_SUCCESS = 'SORT_TRANSACTIONS_SUCCESS'
export const SORT_TRANSACTIONS_ERROR = 'SORT_TRANSACTIONS_ERROR'

export const CLOSE_DATE = 'CLOSE_DATE'
export const CLOSE_DATE_SUCCESS = 'CLOSE_DATE_SUCCESS'
export const CLOSE_DATE_ERROR = 'CLOSE_DATE_ERROR'

export const SET_START_DATE = 'SET_START_DATE'
export const SET_END_DATE = 'SET_END_DATE'
