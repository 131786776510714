import React, { useState } from 'react'
import styled from '@emotion/styled'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { formatCurrency } from '../../../utils'
import CancelIcon from '../../ui/icons/Close'
import EditIcon from '../../ui/icons/Edit'
import DeleteIcon from '../../ui/icons/Delete'
import VerifiedIcon from '../../ui/icons/Verified'
import { updateTransaction, deleteTransaction } from '../../../actions/transactionActions'
import TransactionUpdate from '../TransactionUpdate'

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 85%;
  overflow-y: scroll;
`
const TableItem = styled.div`
  border-radius: .5rem;
  border: 1px solid #ABB3B7;
  display: flex;
  margin: .5rem 0;
  width: 100%;
  &>div{
    width: calc((100% - 5rem) / 6);
  }
`
const BankColor = styled.span`
  width: 2rem;
  ${
    props => props.color ? (
      `background: ${props.color};`
    ) : (
      'background: #000;'
    )
  }
`
const Cell = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0;
  h5{
    color: #565C5E;
    font-size: 1.2rem;
    font-weight: 800;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }
  div{
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
  }
`
const HelpersContainer = styled.span`
  width: 3rem;
  display: flex;
  flex-direction: column;
  span {
    height: calc(100% / 3);
  }
`
const Helper = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 50%;
    fill: #ABB3B7;
  }
  &:hover,
  &:focus{
    svg{
      fill: #0093C6;
    }
  }
`
const getBankColor = (banks, transaction) => {
  const bank = banks.filter(bank => bank.id === transaction.bank && bank.color)
  if (bank.length > 0) {
    return bank[0].color
  } else {
    return '#000'
  }
}
const removeTransaction = async (client, id, transactions, dispatch) => {
  const confirmation = await Swal.fire({
    title: '¿Estás Seguro?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, Eliminar!',
    cancelButtonText: 'Cancelar'
  })
  if (confirmation.value) {
    dispatch(deleteTransaction(client, id, transactions))
  }
}

export default function TransactionList (props) {
  const [updateForm, setUpdateForm] = useState(null)
  const dispatch = useDispatch()
  const { client } = useSelector(state => state.auth)
  const { list: transactions, banks, sorted } = useSelector(state => state.transactions)

  if (transactions.length > 0 && sorted.length === 0) {
    return (
      <ItemsContainer>
        {
          transactions.map(
            (transaction, i) => (
              <TableItem key={`${transaction.id}`}>
                <BankColor color={getBankColor(banks, transaction)} />
                <Cell>
                  <h5>Tipo</h5>
                  <div>{transaction.type}</div>
                </Cell>
                <Cell>
                  <h5>Fecha</h5>
                  <div>{transaction.date}</div>
                </Cell>
                <Cell>
                  <h5>Hora</h5>
                  <div>{transaction.time}</div>
                </Cell>
                <Cell>
                  <h5>Valor</h5>
                  <div>{formatCurrency(transaction.value)}</div>
                </Cell>
                <Cell>
                  <h5>Corresponsal</h5>
                  <div>{transaction.bank}</div>
                </Cell>
                <Cell>
                  <h5>Creado por</h5>
                  <div>{transaction.byName}</div>
                </Cell>
                <HelpersContainer>
                  <Helper onClick={() => dispatch(updateTransaction(client, transaction.id, { verified: !transaction.verified }, transactions))}>
                    {transaction.verified ? <VerifiedIcon /> : <CancelIcon />}
                  </Helper>
                  <Helper onClick={() => setUpdateForm(transaction)}>
                    <EditIcon />
                  </Helper>
                  <Helper onClick={() => removeTransaction(client, transaction.id, transactions, dispatch)}>
                    <DeleteIcon />
                  </Helper>
                </HelpersContainer>
                {
                  updateForm && updateForm.id === transaction.id && <TransactionUpdate transaction={updateForm} trigger={setUpdateForm} />
                }
              </TableItem>
            )
          )
        }
      </ItemsContainer>
    )
  }
  if (sorted.length > 0) {
    return (
      <ItemsContainer>
        {
          sorted.map(
            (transaction, i) => (
              <TableItem key={`${transaction.item.id}`}>
                <BankColor color={getBankColor(banks, transaction.item)} />
                <Cell>
                  <h5>Tipo</h5>
                  <div>{transaction.item.type}</div>
                </Cell>
                <Cell>
                  <h5>Fecha</h5>
                  <div>{transaction.item.date}</div>
                </Cell>
                <Cell>
                  <h5>Hora</h5>
                  <div>{transaction.item.time}</div>
                </Cell>
                <Cell>
                  <h5>Valor</h5>
                  <div>{formatCurrency(transaction.item.value)}</div>
                </Cell>
                <Cell>
                  <h5>Corresponsal</h5>
                  <div>{transaction.item.bank}</div>
                </Cell>
                <Cell>
                  <h5>Creado por</h5>
                  <div>{transaction.item.byName}</div>
                </Cell>
                <HelpersContainer>
                  <Helper onClick={() => dispatch(updateTransaction(client, transaction.item.id, { verified: !transaction.item.verified }, transactions))}>
                    {transaction.item.verified ? <VerifiedIcon /> : <CancelIcon />}
                  </Helper>
                  <Helper onClick={() => setUpdateForm(transaction.item)}>
                    <EditIcon />
                  </Helper>
                  <Helper onClick={() => removeTransaction(client, transaction.item.id, transactions, dispatch)}>
                    <DeleteIcon />
                  </Helper>
                </HelpersContainer>
                {
                  updateForm && updateForm.id === transaction.item.id && <TransactionUpdate transaction={updateForm} trigger={setUpdateForm} />
                }
              </TableItem>
            )
          )
        }
      </ItemsContainer>
    )
  }
  return (
    <ItemsContainer>
      <p>No hay transacciones.</p>
    </ItemsContainer>
  )
}
