import React from 'react'
import styled from '@emotion/styled'
import Layout from '../Layout'
import LoadingIcon from '../../ui/icons/Loading'

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0,147,198, .95);
  margin: 0;
  color: #fff;
  svg{
    fill: #fff;
  }
`
const IconContainer = styled.div`
  width: 20%;
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  
  @keyframes rotate {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }
`

export default function Loading (props) {
  return (
    <Layout title='Cargando...' {...props}>
      <LoadingContainer>
        <IconContainer>
          <LoadingIcon />
        </IconContainer>
        <h2>Cargando...</h2>
      </LoadingContainer>
    </Layout>
  )
}
