import {
  FETCH_BANKS,
  FETCH_BANKS_ERROR,
  FETCH_BANKS_SUCCESS,
  FETCH_CODES,
  FETCH_CODES_ERROR,
  FETCH_CODES_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_SUCCESS,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_ERROR,
  FETCH_TRANSACTIONS_SUCCESS,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_ERROR,
  UPDATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_ERROR,
  DELETE_TRANSACTION_SUCCESS,
  SORT_TRANSACTIONS,
  SORT_TRANSACTIONS_ERROR,
  SORT_TRANSACTIONS_SUCCESS,
  CLOSE_DATE,
  CLOSE_DATE_ERROR,
  CLOSE_DATE_SUCCESS,
  SET_START_DATE,
  SET_END_DATE
} from '../actions/types'

const initialState = {
  banks: [],
  close: {
    loading: false
  },
  codes: [],
  error: null,
  list: [],
  loading: false,
  sorted: [],
  startDate: null,
  endDate: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    //  Start Action
    case FETCH_BANKS:
      return {
        ...state,
        loading: true,
        banks: []
      }
    case FETCH_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: action.payload,
        error: null
      }
    case FETCH_BANKS_ERROR:
      return {
        ...state,
        loading: false,
        banks: [],
        error: action.payload.error
      }
    case FETCH_CODES:
      return {
        ...state,
        loading: true,
        codes: []
      }
    case FETCH_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        codes: action.payload,
        error: null
      }
    case FETCH_CODES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case CREATE_TRANSACTION:
      return {
        ...state,
        loading: true
      }
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case CREATE_TRANSACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        list: []
      }
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        error: null
      }
    case FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        list: [],
        loading: false,
        error: action.payload.error
      }
    case UPDATE_TRANSACTION:
      return {
        ...state
      }
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        list: action.payload,
        sorted: [],
        error: null
      }
    case UPDATE_TRANSACTION_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case DELETE_TRANSACTION:
      return {
        ...state
      }
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        list: action.payload,
        sorted: [],
        error: null
      }
    case DELETE_TRANSACTION_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case SORT_TRANSACTIONS:
      return {
        ...state
      }
    case SORT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        sorted: action.payload,
        error: null
      }
    case SORT_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case CLOSE_DATE:
      return {
        ...state,
        close: { loading: true }
      }
    case CLOSE_DATE_SUCCESS:
      return {
        ...state,
        close: { ...state.close, ...action.payload, loading: false },
        error: null
      }
    case CLOSE_DATE_ERROR:
      return {
        ...state,
        close: null,
        error: action.payload.error
      }
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      }
    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload
      }
    default:
      return state
  }
}
