import * as React from 'react'

function SvgLogo (props) {
  return (
    <svg viewBox='38 3344 90.777 59' {...props}>
      <defs>
        <style>{'.logo_svg__a{fill:#e31937}'}</style>
      </defs>
      <g transform='translate(38 3344)'>
        <circle
          className='logo_svg__a'
          cx={4.554}
          cy={4.554}
          r={4.554}
          transform='translate(31.777)'
        />
        <circle
          className='logo_svg__a'
          cx={4.554}
          cy={4.554}
          r={4.554}
          transform='translate(45.389 33.194)'
        />
        <path
          className='logo_svg__a'
          d='M5.414 59a4.554 4.554 0 114.554-4.554A4.6 4.6 0 015.414 59'
        />
        <circle
          className='logo_svg__a'
          cx={4.554}
          cy={4.554}
          r={4.554}
          transform='translate(.911 33.194)'
        />
        <circle
          className='logo_svg__a'
          cx={4.554}
          cy={4.554}
          r={4.554}
          transform='translate(77.115)'
        />
        <path
          className='logo_svg__a'
          d='M22.669 0H4.554a4.554 4.554 0 000 9.108H22.72A4.554 4.554 0 1022.669 0zM22.669 16.597H4.554a4.554 4.554 0 100 9.108H22.72a4.554 4.554 0 10-.051-9.108zM36.331 33.194H18.216a4.554 4.554 0 100 9.108h18.166a4.535 4.535 0 004.554-4.554 4.645 4.645 0 00-4.605-4.554zM54.446 16.597H36.331a4.554 4.554 0 000 9.108h18.166a4.554 4.554 0 10-.051-9.108zM86.223 16.597H68.057a4.554 4.554 0 100 9.108h18.166a4.554 4.554 0 100-9.108zM68.058 0H49.943a4.554 4.554 0 100 9.108h18.166A4.554 4.554 0 1068.058 0z'
        />
      </g>
    </svg>
  )
}

export default SvgLogo
