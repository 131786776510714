import React from 'react'

function DollarIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 477.8 477.8'
    >
      <path d='M238.9 0C106.9 0 0 106.9 0 238.9s106.9 238.9 238.9 238.9 238.9-106.9 238.9-238.9C477.7 107 370.8.1 238.9 0zm-17.1 221.8h34.1c37.7 0 68.3 30.6 68.3 68.3v17.1c0 37.7-30.6 68.3-68.3 68.3v17.1c0 9.4-7.6 17.1-17.1 17.1-9.4 0-17.1-7.6-17.1-17.1v-17.1c-37.7 0-68.3-30.6-68.3-68.3 0-9.4 7.6-17.1 17.1-17.1 9.4 0 17.1 7.6 17.1 17.1 0 18.9 15.3 34.1 34.1 34.1h34.1c18.9 0 34.1-15.3 34.1-34.1v-17.1c0-18.9-15.3-34.1-34.1-34.1h-34.1c-37.7 0-68.3-30.6-68.3-68.3v-17.1c0-37.7 30.6-68.3 68.3-68.3V85.2c0-9.4 7.6-17.1 17.1-17.1 9.4 0 17.1 7.6 17.1 17.1v17.1c37.7 0 68.3 30.6 68.3 68.3 0 9.4-7.6 17.1-17.1 17.1-9.4 0-17.1-7.6-17.1-17.1 0-18.9-15.3-34.1-34.1-34.1h-34.1c-18.9 0-34.1 15.3-34.1 34.1v17.1c0 18.9 15.3 34.1 34.1 34.1z' />
    </svg>
  )
}

export default DollarIcon
